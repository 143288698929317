import { Link, useStaticQuery, graphql, withPrefix } from "gatsby";
import React, {
	useContext,
	useState,
	useEffect,
	useRef,
	createRef,
} from "react";
import Container from "react-bootstrap/Container";
import {
	ButtonToolbar,
	Col,
	InputGroup,
	Row,
	Spinner,
	ToggleButton,
	ToggleButtonGroup,
} from "react-bootstrap";
import Layout from "../../components/layout";
import Button from "react-bootstrap/Button";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
// import LocalBusinessSchema from "../../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import gsap from "gsap";
import { motion } from "framer-motion";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";

import { Card, Dropdown, DropdownButton } from "react-bootstrap";
import RecordForm from "../../components/record-form";
import MultiStepForm from "../../components/multi-step-form";
import HeroSectionHome from "../../components/hero-section-home";
import BrokerSectionHome from "../../components/broker-section-home";
import ReviewSection from "../../components/review-section";
import ReviewSectionHome from "../../components/review-section";
import RecentLeadsList from "../../components/use-fetch-leads";
import useFetchData from "../../components/use-fetch-data";
import LeadsChart from "../../components/leads-chart";
import StatusPieChart from "../../components/status-pie-chart";
import RecentLeadsTable from "../../components/recent-leads-table";
import BrokersTable from "../../components/brokers-table";
import LeadsTable from "../../components/leads-table";
import AdminLayout from "../../components/admin-layout";
import BrokerLayout from "../../components/broker-layout";
import LeadsTableBroker from "../../components/leads-table-broker";
import withRoleBasedRedirect from "../../components/with-role-based-redirects";
import { useAuth0 } from "@auth0/auth0-react";

const LeadsPage = () => {
	const { user } = useAuth0();
	const namespace = "https://quotestar.co/"; // Namespace used in your Auth0 rule/action
	const brokerId = user[`${namespace}user_metadata`]?.airtableRecordId;
	const apiKey = process.env.GATSBY_MY_SECRET_API_KEY; // Ensure you're using the right environment variable for your API key

	const {
		data: dataBrokers,
		loading: loadingBrokers,
		error: errorBrokers,
	} = useFetchData(
		`/.netlify/functions/fetchAirtableBrokerById?id=${brokerId}`,
		apiKey
	);

	const {
		data: dataLeads,
		loading: loadingLeads,
		error: errorLeads,
	} = useFetchData(
		`/.netlify/functions/fetchAirtableLeadsForBroker?brokerId=${brokerId}`,
		apiKey
	);

	const addDays = (date, days) => {
		const result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	};

	const [selectedOption, setSelectedOption] = useState("Daily leads");
	const [leadsCount, setLeadsCount] = useState(0);

	// Helper function to filter records based on the dateCreated
	const filterRecordsByDate = (startDate, endDate = new Date()) => {
		return dataLeads.filter((record) => {
			const dateCreated = new Date(record.dateCreated);
			return dateCreated >= startDate && dateCreated < endDate;
		});
	};

	// Function to update the leads count based on the selected option
	const updateLeadsData = (option) => {
		let startDate;
		const endDate = new Date(); // Assuming we want to filter up to the current date

		switch (option) {
			case "Daily leads":
				startDate = addDays(endDate, -1);
				break;
			case "Weekly leads":
				startDate = addDays(endDate, -7);
				break;
			case "Monthly leads":
				startDate = addDays(endDate, -30);
				break;
			case "Quarterly leads":
				startDate = addDays(endDate, -90);
				break;
			default:
				startDate = endDate;
		}

		const filteredRecords = filterRecordsByDate(startDate);
		setLeadsCount(filteredRecords.length);
	};

	// Effect hook to update data when the selected option changes
	useEffect(() => {
		updateLeadsData(selectedOption);
	}, [selectedOption, dataLeads]); // Add records to dependency array to update count when records change

	if (loadingLeads) {
		return (
			<BrokerLayout>
				<div
					style={{ width: "100%", height: "100%" }}
					className="position-relative"
				>
					<div
						style={{ top: "50vh" }}
						className="position-absolute start-50 translate-middle"
					>
						<Spinner
							style={{ width: "6rem", height: "6rem" }}
							animation="border"
							role="status"
							variant="primary"
						>
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</div>

					<GatsbySeo title="All Leads" language="en" noindex nofollow />
				</div>
			</BrokerLayout>
		);
	}

	return (
		<>
			{/* <GatsbySeo
				title="Dentist in Exeter | Dental House Exeter"
				description="Highest quality dental care in a warm and welcoming setting, offering the newest in digital dentistry"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Dentist in Exeter | Dental House Exeter",
					description:
						"Highest quality dental care in a warm and welcoming setting, offering the newest in digital dentistry",
					//images: [
					//	{
					//		url: `${data.heroImg?.localFile.publicURL}`,
					//		width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
					//		height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
					//		alt: `${data.heroImg?.altText}`,
					//	},
					//],
				}}
			/> */}

			<div>
				<BrokerLayout>
					<GatsbySeo title="All Leads" language="en" noindex nofollow />
					<Row>
						<Col>
							<LeadsTableBroker title="All Leads" leads={dataLeads} />
						</Col>
					</Row>
				</BrokerLayout>
			</div>
		</>
	);
};

export default withRoleBasedRedirect(LeadsPage, {
	requiredRole: "Broker",
});
