import React, { useState, useMemo } from "react";
import {
	useReactTable,
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
} from "@tanstack/react-table";
import { Table, Pagination, Form, Row, Col } from "react-bootstrap";
import { Link } from "gatsby"; // Import navigate from gatsby
import { FaSearch } from "react-icons/fa";
import filterIcon from "../images/filter.svg";

const BrokersTable = ({ brokers }) => {
	const [search, setSearch] = useState("");
	const [dateFilter, setDateFilter] = useState("All");
	const [subscriptionFilter, setSubscriptionFilter] = useState("Subscription");
	const [sortOrder, setSortOrder] = useState("sortBy");

	const filterDate = (date) => {
		const targetDate = new Date(date);
		const now = new Date();
		const firstDayOfThisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
		const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
		const firstDayOfThisQuarter = new Date(
			now.getFullYear(),
			Math.floor(now.getMonth() / 3) * 3,
			1
		);

		switch (dateFilter) {
			case "This Month":
				return targetDate >= firstDayOfThisMonth && targetDate <= now;
			case "Last Month":
				return targetDate >= lastMonth && targetDate < firstDayOfThisMonth;
			case "This Quarter":
				return targetDate >= firstDayOfThisQuarter && targetDate <= now;
			default:
				return true;
		}
	};

	const subscriptionOptions = useMemo(
		() => ["Subscription", ...new Set(brokers.map((b) => b.subscription))],
		[brokers]
	);

	const filteredData = useMemo(() => {
		return brokers.filter((broker) => {
			const searchMatch = search
				? broker.firstName.toLowerCase().includes(search.toLowerCase()) ||
				  broker.companyName.toLowerCase().includes(search.toLowerCase())
				: true;
			const subscriptionMatch =
				subscriptionFilter === "Subscription"
					? true
					: broker.subscription === subscriptionFilter;
			const dateMatch =
				dateFilter === "All" ? true : filterDate(broker.dateCreated);
			return searchMatch && subscriptionMatch && dateMatch;
		});
	}, [brokers, search, dateFilter, subscriptionFilter]);

	const sortedAndFilteredData = useMemo(() => {
		return [...filteredData].sort((a, b) => {
			const dateA = new Date(a.dateCreated);
			const dateB = new Date(b.dateCreated);
			if (sortOrder === "sortBy" || sortOrder === "dateDescending") {
				return dateB - dateA;
			} else {
				return dateA - dateB;
			}
		});
	}, [filteredData, sortOrder]);

	const columns = useMemo(
		() => [
			{
				size: 90,
				accessorKey: "firstName",
				header: "Name",
				cell: (info) => (
					<div className="truncate py-4">
						<div className="py-2">{info.getValue()}</div>
					</div>
				),
			},
			{
				size: 120,
				accessorKey: "companyName",
				header: "Company Name",
				cell: (info) => (
					<div className="truncate py-4">
						<div className="py-2">{info.getValue()}</div>
					</div>
				),
			},
			{
				size: 60,
				accessorKey: "leadsAssigned",
				header: "Assigned Leads",
				cell: (info) => (
					<div className="truncate py-4">
						<div className="py-2">{info.getValue()?.length}</div>
					</div>
				),
			},

			{
				size: 100,
				accessorKey: "phoneNumber",
				header: "Phone No.",
				cell: (info) => (
					<div className="truncate py-4">
						<div className="py-2">{info.getValue()}</div>
					</div>
				),
			},
			{
				accessorKey: "email",
				header: "Email",
				cell: (info) => (
					<div className="truncate py-4">
						<div className="py-2">{info.getValue()}</div>
					</div>
				),
			},

			{
				size: 90,
				accessorKey: "subscription",
				header: "Subscription",
				cell: (info) => (
					<div className="truncate py-4">
						<span
							className="p-2 w-100 Jakarta-Bold "
							style={{
								display: "inline-block",

								color: "#fff",
								textAlign: "center",

								borderRadius: "100px", // Rounded corners
								backgroundColor:
									info.getValue() === "Basic" ? "#1470D6" : "#E63737",
							}}
						>
							{info.getValue()}
						</span>
					</div>
				),
			},
		],
		[]
	);

	const table = useReactTable({
		data: sortedAndFilteredData,
		columns,

		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
	});

	return (
		<div>
			<h1 className="mb-4">Brokers list</h1>
			<div
				style={{ borderRadius: "13px", border: "1px solid #D6D6D6" }}
				className="bg-white px-3 mb-4"
			>
				<Row className="align-items-center justify-content-between">
					<Col md={6}>
						<Row className="align-items-center">
							{/* Assuming you have a filter icon variable or component */}
							<Col md={2}>
								<div className="text-center">
									<img
										className=" d-none d-lg-inline-block"
										src={filterIcon}
										alt="Filter"
										style={{
											width: "30px",
										}}
									/>
								</div>
							</Col>
							<Col
								style={{
									borderRight: "1px solid #D6D6D6",
									borderLeft: "1px solid #D6D6D6",
								}}
								className="custom-dropdown py-4"
								md
							>
								<Form.Select
									aria-label="Date Filter"
									value={dateFilter}
									onChange={(e) => setDateFilter(e.target.value)}
								>
									<option value="All">Date</option>
									<option value="This Month">This Month</option>
									<option value="Last Month">Last Month</option>
									<option value="This Quarter">This Quarter</option>
								</Form.Select>
							</Col>
							<Col
								style={{ borderRight: "1px solid #D6D6D6" }}
								className="custom-dropdown py-4"
								md
							>
								<Form.Select
									aria-label="Subscription Filter"
									value={subscriptionFilter}
									onChange={(e) => setSubscriptionFilter(e.target.value)}
								>
									{subscriptionOptions.map((option, index) => (
										<option key={index} value={option}>
											{option}
										</option>
									))}
								</Form.Select>
							</Col>
							<Col
								style={{ borderRight: "1px solid #D6D6D6" }}
								className="custom-dropdown py-4"
								md
							>
								<Form.Select
									aria-label="Sort By Date"
									value={sortOrder}
									onChange={(e) => setSortOrder(e.target.value)}
								>
									<option disabled value="sortBy">
										Sort By
									</option>
									<option className="py-2" value="dateDescending">
										Descending
									</option>
									<option className="py-2" value="dateAscending">
										Ascending
									</option>
								</Form.Select>
							</Col>
						</Row>
					</Col>
					<Col md={4}>
						<div
							className="d-flex align-items-center rounded-pill bg-med-grey p-1"
							style={{ overflow: "hidden" }}
						>
							<FaSearch className="ms-3" /> {/* FontAwesome icon placeholder */}
							<Form.Control
								type="search"
								placeholder="Search"
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								className="flex-grow-1 border-0 bg-med-grey ms-3"
								style={{ outline: "none", boxShadow: "none" }}
							/>
						</div>
					</Col>
				</Row>
			</div>

			<div
				style={{
					padding: "20px",
					backgroundColor: "#fff",
					borderRadius: "8px",
					boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
				}}
			>
				<div
					style={{
						border: "1px solid #D6D6D6",
						borderRadius: "13px",
						overflow: "hidden",
					}}
				>
					<Table className="mb-0" hover borderless size="lg">
						<thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<tr style={{ backgroundColor: "#F1F4F9" }} key={headerGroup.id}>
									{headerGroup.headers.map((header) => (
										<th
											className="p-3"
											style={{ backgroundColor: "#F1F4F9", fontSize: "90%" }}
											key={header.id}
										>
											{header.isPlaceholder
												? null
												: flexRender(
														header.column.columnDef.header,
														header.getContext()
												  )}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody>
							{table.getRowModel().rows.map((row, index) => (
								<tr
									style={{
										borderBottom:
											index !== table.getRowModel().rows.length - 1
												? "1px solid #D6D6D6"
												: "",
									}}
									key={row.id}
								>
									{row.getVisibleCells().map((cell) => (
										<td
											key={cell.id}
											className="px-3 text-schema-grey truncate"
											style={{
												width: cell.column.getSize(),
												maxWidth: cell.column.getSize(),
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}
										>
											<Link
												to={`/admin/broker-details?brokerId=${row.original.airtableId}`}
												style={{
													textDecoration: "none",
													color: "inherit",

													overflow: "hidden",
													textOverflow: "ellipsis",
												}}
											>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext()
												)}
											</Link>
										</td>
									))}
								</tr>
							))}
						</tbody>
					</Table>
				</div>
				<div className="mt-3 d-flex justify-content-between align-items-center">
					<span className="text-schema-grey">
						<p>
							Showing{" "}
							{table.getState().pagination.pageIndex *
								table.getState().pagination.pageSize +
								1}{" "}
							-
							{Math.min(
								(table.getState().pagination.pageIndex + 1) *
									table.getState().pagination.pageSize,
								sortedAndFilteredData.length
							)}{" "}
							of {sortedAndFilteredData.length}
						</p>
					</span>
					<Pagination>
						<Pagination.Prev
							onClick={() => table.previousPage()}
							disabled={!table.getCanPreviousPage()}
						/>
						<Pagination.Next
							onClick={() => table.nextPage()}
							disabled={!table.getCanNextPage()}
						/>
					</Pagination>
				</div>
			</div>
		</div>
	);
};

export default BrokersTable;
