import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { IoMdPerson } from "react-icons/io";
import { useAuth0 } from "@auth0/auth0-react";
import useFetchData from "./use-fetch-data";
import Switch from "react-switch"; // Import react-switch

const BrokerTopBarAdmin = () => {
	const { user } = useAuth0();
	const namespace = "https://quotestar.co/";
	const brokerName = user ? user[`${namespace}user_metadata`]?.brokerName : "";
	const userRoles = user ? user["https://quotestar.co/roles"] : [];
	const brokerId = user[`${namespace}user_metadata`]?.airtableRecordId;

	// Assuming you have a way to fetch the current paused status of the broker
	// Initialize receiveLeads based on not being paused (i.e., if paused is true, receiveLeads should be false)
	const [receiveLeads, setReceiveLeads] = useState(false);

	// Fetch the broker's data including the paused status
	const {
		data: brokerData,
		loading: loadingBroker,
		error: errorBroker,
	} = useFetchData(
		`/.netlify/functions/fetchAirtableBrokerById?id=${brokerId}`,
		process.env.GATSBY_MY_SECRET_API_KEY
	);

	// Update the receiveLeads state based on fetched data
	useEffect(() => {
		if (brokerData) {
			// Assuming 'paused' is a boolean field in your brokerData
			setReceiveLeads(!brokerData.paused);
		}
	}, [brokerData]);

	// Function to toggle the receive leads state and update the backend
	const toggleReceiveLeads = async () => {
		const newReceiveLeadsState = !receiveLeads;
		setReceiveLeads(newReceiveLeadsState);

		// Call your Netlify function to update the paused status
		try {
			const response = await fetch("/.netlify/functions/updateBrokerPaused", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"x-api-key": process.env.GATSBY_MY_SECRET_API_KEY, // Ensure you use the correct API key
				},
				body: JSON.stringify({
					id: brokerId,
					paused: !newReceiveLeadsState, // The new paused status is the opposite of receiveLeads
				}),
			});

			if (!response.ok) {
				throw new Error(`Error: ${response.statusText}`);
			}

			const responseBody = await response.json();
		} catch (error) {}
	};

	return (
		<div style={{ maxWidth: "100%" }}>
			<div style={{ height: "85px" }} className="w-100"></div>
			<div
				className="bg-primary py-3 pe-5 top-0 start-0"
				style={{ position: "fixed", zIndex: "99999", width: "100%" }}
			>
				<Row className="g-0">
					<Col className="text-end">
						<div className="d-inline-block">
							<div className="d-flex align-items-center">
								{/* Toggle Switch for Receive Leads */}
								{/* Title for the switch */}
								{!loadingBroker && (
									<div className="me-4 d-flex align-items-center">
										<p className="mb-0 pb-0 text-white Jakarta-Bold me-2">
											Receive Leads?
										</p>
										{/* React Switch */}
										<Switch
											onChange={toggleReceiveLeads}
											checked={receiveLeads}
											onColor="#29B9F2" // Customize the switch on-color
											offColor="#767676" // Customize the switch off-color
										/>
									</div>
								)}

								<div
									style={{
										width: "50px",
										height: "50px",
										borderRadius: "100%",
									}}
									className="bg-white d-flex me-4 align-items-center justify-content-center"
								>
									<IoMdPerson className="text-primary fs-4" />
								</div>
								<div className="d-block">
									<p className="text-white pb-0 mb-1 Jakarta-Bold d-block fs-6">
										{brokerName || "None"}
									</p>
									<p
										style={{ fontSize: "90%" }}
										className="text-white pb-0 mb-0"
									>
										{userRoles[0] || "None"}
									</p>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default BrokerTopBarAdmin;
