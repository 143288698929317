import React from "react";
import { CookieBanner } from "@connorrjm/rjm-cookie-banner";
import Footer from "./footer";
import Navigation from "./navigation";
import { Helmet } from "react-helmet";
import Sidebar from "./side-nav";
import TopBarAdmin from "./top-bar-admin";
import { Container, Row, Col } from "react-bootstrap";
import BrokerSidebar from "./broker-side-bar";
import BrokerTopBarAdmin from "./broker-top-bar";

const BrokerLayout = ({ children }) => {
	return (
		<>
			{/* <CookieBanner /> */}
			<section style={{ minHeight: "100vh" }} className="bg-med-grey ">
				<Container fluid className="px-0 mx-0">
					<Row className="g-0">
						<Col lg={2}>
							<BrokerSidebar />
						</Col>
						<Col className="position-relative" lg={10}>
							<BrokerTopBarAdmin />
							<Row className="py-5 py-lg-7">
								<Col className="px-5">{children}</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</section>
		</>
	);
};

export default BrokerLayout;
