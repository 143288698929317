import React, { useState, useEffect } from "react";
import { Nav, Navbar } from "react-bootstrap";
import {
	HouseDoorFill,
	PersonLinesFill,
	PersonPlusFill,
	FileEarmarkTextFill,
	PencilSquare,
	BoxArrowRight,
} from "react-bootstrap-icons";

import Logo from "../images/white-logo.svg";
import { FaChartPie, FaListAlt } from "react-icons/fa";
import { BsBank2 } from "react-icons/bs";
import { MdAddToPhotos, MdEditSquare } from "react-icons/md";
import AddBroker from "../images/additem.svg";
import AllLeads from "../images/edit.svg";
import EditSite from "../images/global-edit.svg";
import LogoutLogo from "../images/logout.svg";
import { TbLogout2, TbEdit } from "react-icons/tb";
import { CgProfile } from "react-icons/cg";
import { useAuth0 } from "@auth0/auth0-react";

const BrokerSidebar = () => {
	// State to manage the active link based on the current page
	const [activeKey, setActiveKey] = useState("/");
	const { logout } = useAuth0();
	const handleLogout = () => {
		// Calling the logout method
		logout({ returnTo: window.location.origin });
	};
	useEffect(() => {
		if (typeof window !== "undefined") {
			// Update the state to match the current path
			setActiveKey(`${window.location.pathname}`);
		}
	}, []);

	const navItems = [
		{
			eventKey: "/broker",
			icon: <FaChartPie className="fs-5" />,
			text: "Dashboard",
		},
		{
			eventKey: "/broker/leads",
			icon: <FaListAlt className="fs-5" />,
			text: "All Leads",
		},

		{
			eventKey: "/broker/request-categories",
			icon: <MdAddToPhotos className="fs-5" />,
			text: "Request",
		},

		{
			eventKey: "/broker/profile",
			icon: <CgProfile className="fs-5" />,
			text: "My Profile",
		},
	];

	return (
		<Navbar
			bg="primary"
			variant="dark"
			className="flex-column vh-100 px-4 pt-4"
			style={{ width: "15vw", position: "fixed", zIndex: "999999" }}
		>
			<Navbar.Brand
				className="mb-4 text-center me-0 w-100"
				style={{ color: "white" }}
			>
				<img
					className=" d-none d-lg-inline-block"
					src={Logo}
					alt="QuoteStar Logo"
					style={{
						width: "140px",
					}}
				/>
			</Navbar.Brand>
			<Nav
				className="flex-column  w-100"
				activeKey={activeKey}
				onSelect={(selectedKey) => setActiveKey(selectedKey)}
			>
				{navItems.map((item, index) => (
					<div>
						<div
							style={{ width: "9px", borderRadius: "4px", height: "56px" }}
							className={` ${
								activeKey === item.eventKey ? "" : "d-none"
							} bg-white position-absolute start-0 translate-middle-x`}
						></div>
						<Nav.Link
							key={index}
							eventKey={item.eventKey}
							href={item.eventKey}
							style={{ borderRadius: "6px" }}
							className={`d-flex align-items-center py-3 ps-3  ms-2 mb-3 ${
								activeKey === item.eventKey
									? "bg-white text-primary Jakarta-Bold"
									: "text-white"
							}`}
						>
							{item.icon}
							<span className="ms-3">{item.text}</span>
						</Nav.Link>
					</div>
				))}
				<hr className="text-white" />
			</Nav>
			<div className="mt-auto w-100">
				<hr className="text-white" />
				<Nav.Link
					onClick={handleLogout}
					className={`d-flex align-items-center ps-3 ms-2 mb-3 text-white`}
				>
					<TbLogout2 className="fs-5" />
					<span className="ms-3">Logout</span>
				</Nav.Link>
			</div>
		</Navbar>
	);
};

export default BrokerSidebar;
